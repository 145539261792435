import React, { useState } from "react";
import axios from 'axios';
import { InputGroup, Form, Button, FormGroup, FormControl, FormLabel, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Login2.css";
import '../ettbootstrap.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AlertComponent from '../components/Alert/AlertComponent';
import { createNextUrl } from './GenerateRedirect'

export default function LoginForm() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [eyeIsOpen, setEyeIsOpen] = useState(true);
    const [message, setMessage] = useState(null);
    const [messageVariant, setMessageVariant] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const createNextUrlVar = createNextUrl()
    console.log(createNextUrlVar)

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        let url = 'https://authentication.ettbrasil.com.br/login';

        var bodyFormData = new FormData();
        bodyFormData.append('username', email);
        bodyFormData.append('password', password);
        setWaiting(true);
        axios({
            method: 'POST',
            url: url,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true
        })
            .then(res => {
                setWaiting(false)
                setMessage('Login was successfull. You will be redirected.')
                setMessageVariant('success')
                if (createNextUrlVar !== undefined && res.status === 200) {
                    window.location.href = createNextUrlVar
                } else {
                    setMessage('Login was successfull but you won\'t be redirected.')
                }
            }).catch(err => {
                setWaiting(false);
                if(err.response.status === 401) {
                    setMessage('Wrong credentials. Please try again.')
                    setMessageVariant('danger')
                }
            })
       
    }

    function togglePassword() {
        let passwordField = document.getElementById("password");
        if (passwordField.type === "password") {
            passwordField.type = "text";
            setEyeIsOpen(false)
        } else {
            passwordField.type = "password";
            setEyeIsOpen(true)
        }
    }

    return (
        <div className="Login">
            <div class="container-fluid">
                <div class="row no-gutter">
                    <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
                    <div class="col-md-8 col-lg-6">
                        <div class="login d-flex align-items-center py-5">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-9 col-lg-8 mx-auto">
                                        <h3 class="login-heading mb-4">Welcome back!</h3>
                                        <form onSubmit={handleSubmit}>
                                            <h1>Sign in</h1>
                                            <NotificationBar message={message} messageVariant={messageVariant} clearMessage={() => setMessage(null)} />
                                            <FormGroup controlId="email" bssize="large">
                                                <FormLabel>Email</FormLabel>
                                                <FormControl
                                                    required
                                                    autoFocus
                                                    type="email"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    autoComplete="username"
                                                />
                                                <Form.Text className="text-muted">
                                                    We'll never share your email with anyone else.
                    </Form.Text>
                                            </FormGroup>
                                            <FormGroup controlId="password" bssize="large">
                                                <FormLabel>Password</FormLabel>
                                                <InputGroup>
                                                    <FormControl
                                                        required
                                                        value={password}
                                                        onChange={e => setPassword(e.target.value)}
                                                        type="password"
                                                        autoComplete="current-password"
                                                    />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text>
                                                            <i onClick={e => togglePassword()} id="toggle-password" >{eye(eyeIsOpen)}</i></InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormButton isWaiting={waiting} validateForm={() => validateForm()} />
                                        </form>
                                    </div> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}


const eye = (eyeOpen) => {

    if (eyeOpen) {
        return <FontAwesomeIcon icon={faEye} />
    } else {
        return <FontAwesomeIcon icon={faEyeSlash} />
    }
}

const NotificationBar = props => {
    return (<AlertComponent clearMessage={() => props.clearMessage()} message={props.message} messageVariant={props.messageVariant} />);

}

const FormButton = props => {
    if (!props.isWaiting) {
        return (<Button block bssize="large" disabled={!props.validateForm()} type="submit">
            Sign In
        </Button>)
    } else {
        return (
            <Button variant="primary" block bssize="large" disabled>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            </Button>)
    }
}