import React from 'react';
import LoginForm from './Pages/LoginForm'

export const App = () => {
  return (
    <LoginForm />
  );
}

export default App;
