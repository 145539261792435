export const createNextUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let baseUrl = urlParams.get('next');
    let responseType = urlParams.get('response_type')
    let clientId = urlParams.get('client_id')
    let codeChallenge = urlParams.get('code_challenge')
    let codeChallengeMethod = urlParams.get('code_challenge_method')
    let redirectUri = urlParams.get('redirect_uri')
    let state = urlParams.get('state')
    if (redirectUri !== null) {
        return baseUrl + '&response_type=' + responseType + '&client_id=' + clientId + '&code_challenge=' + codeChallenge + '&code_challenge_method=' + codeChallengeMethod + '&redirect_uri=' + redirectUri + '&state=' + state + '&' + document.cookie
    }
    return undefined
}