import React from 'react';
import { Alert} from "react-bootstrap";

function AlertComponent(props) {   
    if (props.message !== null && props.messageVariant !== null) {
        return (
            <Alert variant={props.messageVariant} onClose={() => props.clearMessage()} dismissible>
                <p>
                    {props.message}
              </p>
            </Alert>
        );
       
    }
    return <></>;
}

export default AlertComponent;